export type AliexpressProduct = {
    ProductId: string
    "Image Url": string
    "Video Url": string
    "Product Desc": string
    "Origin Price": string
    "Discount Price": string
    Discount: string
    Currency: string
    "Direct linking commission rate (%)": number
    "Estimated direct linking commission": string
    "Indirect linking commission rate (%)": number
    "Estimated indirect linking commission": string
    Sales180Day: number
    "Positive Feedback": string
    "Promotion Url": string
    "Code Name": string
    "Code Start Time": string
    "Code End Time": string
    "Code Value": string
    "Code Quantity": number
    "Code Minimum Spend": string
}

export default class AliexpressProductCsvParser {
    private row: string[]

    constructor(row: string[]) {
        this.row = row
    }

    private getProductId() {
        return this.row[0]
    }

    private getImageUrl() {
        return this.row[1]
    }

    private getVideoUrl() {
        return this.row[2]
    }

    private getDescription() {
        return this.row[3]
    }

    private getOriginPrice() {
        return this.row[4]
    }

    private getDiscountPrice() {
        return this.row[5]
    }

    private getDiscount() {
        return this.row[6]
    }

    private getCurrency() {
        return this.row[7]
    }

    private getDirectLinkingCommissionRate() {
        return this.row[8]
    }

    private getEstimatedDirectLinkingCommission() {
        return this.row[9]
    }

    private getIndirectLinkingCommissionRate() {
        return this.row[10]
    }

    private getEstimatedIndirectLinkingCommission() {
        return this.row[11]
    }

    private getSales180Day() {
        return this.row[12]
    }

    private getPositiveFeedback() {
        return this.row[13]
    }

    private getPromotionUrl() {
        return this.row[14]
    }

    private getCodeName() {
        return this.row[15]
    }

    private getCodeStartTime() {
        return this.row[16]
    }

    private getCodeEndTime() {
        return this.row[17]
    }

    private getCodeValue() {
        return this.row[18]
    }

    private getCodeQuantity() {
        return this.row[19]
    }

    private getCodeMinimumSpend() {
        return this.row[20]
    }

    getProduct(): AliexpressProduct {
        return {
            ProductId: this.getProductId(),
            "Image Url": this.getImageUrl(),
            "Video Url": this.getVideoUrl(),
            "Product Desc": this.getDescription(),
            "Origin Price": this.getOriginPrice(),
            "Discount Price": this.getDiscountPrice(),
            Discount: this.getDiscount(),
            Currency: this.getCurrency(),
            "Direct linking commission rate (%)": parseFloat(this.getDirectLinkingCommissionRate()),
            "Estimated direct linking commission": this.getEstimatedDirectLinkingCommission(),
            "Indirect linking commission rate (%)": parseFloat(this.getIndirectLinkingCommissionRate()),
            "Estimated indirect linking commission": this.getEstimatedIndirectLinkingCommission(),
            Sales180Day: parseInt(this.getSales180Day()),
            "Positive Feedback": this.getPositiveFeedback(),
            "Promotion Url": this.getPromotionUrl(),
            "Code Name": this.getCodeName(),
            "Code Start Time": this.getCodeStartTime(),
            "Code End Time": this.getCodeEndTime(),
            "Code Value": this.getCodeValue(),
            "Code Quantity": parseInt(this.getCodeQuantity()),
            "Code Minimum Spend": this.getCodeMinimumSpend(),
        }
    }
}