import { Row, Col, Tabs, Tab } from "react-bootstrap"
import RakutenSearch from "./rakuten-search"
import RakutenImport from "./rakuten-import"

function Rakuten() {

    return (
        <Row>
            <Col>
                <Tabs
                    defaultActiveKey="search"
                    className="mb-3"
                >
                    <Tab eventKey="search" title="Search">
                        <RakutenSearch />
                    </Tab>
                    <Tab eventKey="import" title="Import">
                        <RakutenImport />
                    </Tab>
                </Tabs>
            </Col>
        </Row>
    )
}

export default Rakuten