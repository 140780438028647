import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { usePapaParse } from 'react-papaparse';
import AliexpressCsvParser, { AliexpressProduct } from "./aliexpress-product-csv-parser.class";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/Store";
import { setMessage } from "../../Store/Notification/Notification.slice";
import { useImportRakutenProductsMutation } from "../../Store/Feed/Feed.service";

const ALIEXPRESS_MERCHANT_ID = '6755b8e28d159561dec5834f'

type CsvParseResult = { data?: string[][] }

function RakutenImport() {

    const { readString } = usePapaParse();
    const dispatch = useDispatch<AppDispatch>()
    const [csv, setCsv] = useState<string | ArrayBuffer | null>(null)
    const [products, setProducts] = useState<AliexpressProduct[] | null>(null)
    const inputRef = useRef<HTMLInputElement | null>(null);

    const [importRakutenProducts] = useImportRakutenProductsMutation()

    const generateProductsFromCSV = useCallback((data?: string[][]) => {
        if (data) {
            const products: AliexpressProduct[] = []
            for (const row of data) {
                const parser = new AliexpressCsvParser(row)
                const product = parser.getProduct()
                products.push(product)
            }
            setProducts(products)
            setCsv(null)
        }
    }, [setProducts, setCsv]);

    const onCsvDataUpdate = useCallback((csvData: CsvParseResult) => {
        if (csvData.data) {
            csvData.data.shift()
            generateProductsFromCSV(csvData.data)
        }
    }, [generateProductsFromCSV]);

    const handleReadString = useCallback((csv: string) => {
        readString(csv, {
            worker: true,
            complete: (results: CsvParseResult) => {
                onCsvDataUpdate(results)
            },
        });
    }, [readString, onCsvDataUpdate]);

    useEffect(() => {
        if (csv) {
            handleReadString(csv.toString())
        }
    }, [csv, handleReadString])

    const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            let text = await getFileText(e.target.files[0]);
            if (text) {
                text = text.toString()
                setCsv(text)
            } else {
                console.error('Empty file')
            }
        }
    }

    const getFileText = (file: File) => {
        return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
            let text: string | ArrayBuffer | null = '';
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsText(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                text = reader.result;
                resolve(text);
            };
            reader.onerror = (event: any) => {
                reject(event)
            }
        });
    }

    const onImportClick = async () => {
        if (products) {
            const confirm = window.confirm(`Import ${products.length} products?`)
            if (confirm) {
                try {
                    const result = await importRakutenProducts({ merchantId: ALIEXPRESS_MERCHANT_ID, products }).unwrap()
                    if (result.insertedCount > 0 || result.modifiedCount > 0 || result.upsertedCount > 0) {
                        let message = ''

                        if (result.matchedCount > 0) {
                            message += `Matched ${result.matchedCount} products `
                        }
                        if (result.insertedCount > 0) {
                            message += `Imported ${result.insertedCount} products `
                        }
                        if (result.modifiedCount > 0) {
                            message += `Updated ${result.modifiedCount} products `
                        }
                        if (result.upsertedCount > 0) {
                            message += `Upserted ${result.upsertedCount} products `
                        }
                        dispatch(setMessage({
                            message,
                            variant: 'success',
                        }))
                    } else {
                        dispatch(setMessage({
                            message: 'Failed to import products',
                            variant: 'danger',
                        }))
                    }
                    clearForm()
                } catch (err) {
                    dispatch(setMessage({
                        message: (err as Error).message,
                        variant: 'danger',
                    }))
                }
            }
        }
    }

    const renderAliexpressProductsTable = () => {
        if (products) {
            let headers: string[] = Object.keys(products[0])
            const body = products
            return (
                <>
                    <Button
                        variant={'primary'}
                        disabled={!products}
                        onClick={onImportClick}
                    >
                        Import
                    </Button>
                    <Table>
                        <thead>
                            <tr>
                                {headers.map((header, index) => (
                                    <th key={`header${index}`}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {body?.map((row: AliexpressProduct, rowIndex: number) => (
                                <tr key={`row${rowIndex}`}>
                                    {headers.map((header, cellIndex) => (
                                        <td key={`val${cellIndex}`}>{(row as any)[header]}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
            )
        }
    }

    const clearForm = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = ''
            setCsv(null)
            setProducts(null)
        }
    }

    return (
        <>
            <Card className="mb-3">
                <Card.Header>Import Aliexpress Products</Card.Header>
                <Card.Body>
                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label>Upload CSV with Aliexpress products</Form.Label>
                        <Form.Control ref={inputRef} type="file" onChange={handleFileChange} />
                    </Form.Group>
                    <Button
                        variant="secondary"
                        disabled={!csv && !products}
                        onClick={clearForm}
                    >Clear</Button>
                </Card.Body>
            </Card>
            {products?.length ? <Card>
                <Card.Header>Pending Import Products</Card.Header>
                <Card.Body>
                    {renderAliexpressProductsTable()}
                </Card.Body>
            </Card> : null}
        </>
    );
}

export default RakutenImport;
